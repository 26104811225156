import 'react-app-polyfill/stable';
import TagManager from 'react-gtm-module';
import React from 'react';
import ReactDOM from 'react-dom';
import { registerObserver } from 'react-perf-devtool';

import './index.scss';
import Root from 'views/Root/Root';
import * as serviceWorker from './serviceWorker';
import './i18n';
import ReactGA from "react-ga4";

const GTM_KEY = process.env.REACT_APP_GTM;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const GA_MEASUREMENT_ID_MOBILE = process.env.REACT_APP_GA_MEASUREMENT_ID_MOBILE;

registerObserver();
const tagManagerArgs = {
  gtmId: GTM_KEY,
  dataLayerUpsellSummary: 'UpsellSummary',
  dataLayerDiets: 'dataLayerDiets'
};

if (GTM_KEY) {
  TagManager.initialize(tagManagerArgs);
}

if(window.location.hostname == 'ketoswitch.lifeswitcher.com') {
  if (GA_MEASUREMENT_ID) {
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }
}else if(window.location.hostname == 'ketoswitch9.lifeswitcher.com'){
  if (GA_MEASUREMENT_ID_MOBILE) {
    ReactGA.initialize(GA_MEASUREMENT_ID_MOBILE);
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
